/* GLOBAL */

:root {
  /* Colors */
  --color-black: rgb(0, 0, 0);
  --color-white: rgb(255, 255, 255);
  --color-hoval-red: rgb(226, 0, 26);
  --color-hoval-gray: rgb(87, 87, 87);
  --color-hoval-light-gray: rgb(198, 198, 198);
  --color-hoval-verylight-gray: rgb(227, 227, 227);
  --color-rehau-magenta: #CB2C61;

  @media screen and (max-width: 768px), (max-height: 512px) {
  }
}


/* ALLGEMEIN */

html,
body {
  position: fixed;
  width: 100%; 
  height: 100%;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: "Open Sans", Arial, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-hoval-red);
  color: var(--color-black);
  overflow: hidden;
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}
html {
  height: -webkit-fill-available;
}

#root {
  height: 100vh;
  overflow: hidden;
}

/* UI */ 

button:focus {outline:0;}
div:focus {outline:0;}

input[type=range] {
  height: 13px;
  -webkit-appearance: none;
  margin: 10px 0;
  background-color: transparent;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: #fff;
  border-radius: 2px;
}
input[type=range]::-webkit-slider-thumb {
  border: 0px solid #000000;
  height: 25px;
  width: 25px;
  border-radius: 25px;
  background: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -10px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #fff;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: #fff;
  border-radius: 2px;
}
input[type=range]::-moz-range-thumb {
  height: 30px;
  width: 30px;
  border-radius: 30px;
  background: #FF5226;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #fff;
  border: 0px solid #000000;
  border-radius: 4px;
  box-shadow: 0px 0px 0px #000000;
}
input[type=range]::-ms-fill-upper {
  background: #fff;
  border-radius: 4px;
}
input[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 2px #000000;
  border: 0px solid #000000;
  height: 30px;
  width: 15px;
  border-radius: 5px;
  background: #FF5226;
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: #fff;
}
input[type=range]:focus::-ms-fill-upper {
  background: #fff;
}



/* FONTS */

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/OpenSans-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/OpenSans-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/OpenSans-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: url("./assets/fonts/OpenSans-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  src: url("./assets/fonts/OpenSans-Bold.ttf") format("truetype");
}
