// nutze Client Settings
@import "./../global.scss";

@keyframes fadeIn {
  0% {opacity:0; margin-top: 50px;}
  100% {opacity:1; margin-top: 0px;}
}

.content-browser {
  display: flex;
  justify-content: center;
  height: 60%;
  width: 100%;
  margin: auto;
  margin-top: 170px;
  // padding: 15px;
  transition: all 1s ease-in-out;
  background-color: transparent;
  border-radius: 10px;
  // overflow: hidden;
  filter: drop-shadow(1px 4px 5px rgba(0, 0, 0, 0.3));

  &.small {
    width: 90%;
    // justify-content: flex-start;
    border-radius: 10px;
    background-color: var(--color-white);
    // filter: drop-shadow(1px 4px 5px rgba(0, 0, 0, 0.3));

    .intro-buttons {
      transition: all 1s;
      width: 40%;
      border-right: 4px solid #eee;
      // filter: drop-shadow(1px 4px 5px rgba(0, 0, 0, 0));

      .intro-button {
        width: 90%;
        // border-radius: 0;

        // filter: drop-shadow(1px 4px 5px rgba(0, 0, 0, 0));

        .title {
          font-size: 20pt;
        }
      }
    }

    .folder-buttons {
      .folder-button {
        .thumb {
          min-width: 150px;
          height: calc(150px / (16 / 9));
        }
        .title {
          transform: scale(1);
          transform-origin: 0 0;
          transition: all 1s;
        }
      }
    }

    &.play-video {
      .intro-buttons {
        width: 0%;
        border-right: 4px solid transparent;
      }

      .folder-button {
        padding-top: 20px;
        padding-bottom: 0px;
        min-height: 80px;

        .thumb {
          min-width: 100px;
          height: calc(100px / (16 / 9));
        }
        .title {
          transform: scale(0.7);
          transform-origin: 0 0;
          transition: all 1s;
          padding-top: 0px;
        }
      }
    }

    .video-buttons {
      width: 0%;
    }
  }

  &.big {
    width: 80%;

    .folder-buttons {
      width: 0%;
    }

    .video-buttons {
      width: 0%;
    }
  }

  &.top {
    .intro-buttons {
      width: 0%;
      .intro-button {
        height: 30%;
        .title {
          font-size: 15pt;
        }
      }
      //   min-width: 100px;
    }

    .folder-buttons {
      .folder-button {
        padding: 5px;

        .thumb {
          width: 50px;
          margin-right: 10px;
        }

        .title {
          font-size: 12pt;
        }
      }
    }

    .video-buttons {
      width: 100%;

      .video-button {
        padding: 5px;

        .thumb {
          width: 50px;
          margin-right: 10px;
        }

        .title {
          font-size: 10pt;
        }
      }
    }
  }

  &.play-video {
    // flex-direction: column;
    width: 30%;
  }

  &.bottom {
    margin-top: 235px;
  }

  .intro-buttons {
    display: flex;
    justify-content: space-between;
    // gap: 40px;
    width: calc(100% - 10px);
    height: calc(100% - 0px);
    transition: all 1s;
    overflow: hidden;
    margin: 0px;
    // filter: drop-shadow(1px 4px 5px rgba(0, 0, 0, 0.3));
    border-right: 4px solid transparent;
    animation: fadeIn ease-in-out 1s;

    &.touch {
      width: calc(100% - 300px);
      height: calc(100% - 10px);
    }

    .intro-button {
      width: 40%;
      height: calc(100% - 40px);
      padding: 20px;
      border: 1px solid var(--color-white);
      border-radius: 15px;
      white-space: nowrap;
      background-color: var(--color-white);
      // filter: drop-shadow(1px 4px 5px rgba(0, 0, 0, 0.3));
      transition: all 1s;

      font-weight: bold;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      .teaser {
        transition: all 1s;
        width: 90%;
        height: 70%;
        border-radius: 10px;
        overflow: hidden;
        background-color: var(--color-white);
        box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.2);

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .title {
        transition: all 1s;
        font-size: 30pt;
        color: var(--color-hoval-gray);
        padding-top: 30px;
        text-align: center;
        min-height: 90px;
      }

      &.hidden {
        opacity: 0;
        min-width: 0;
        width: 0;
        padding-left: 0;
        padding-right: 0;
        border-right: none;
        border-left: none;
      }
    }
  }

  .folder-buttons {
    display: flex;
    flex-direction: column;
    // background-color: rgba(0, 0, 0, 0.1);
    transition: all 1s;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
    padding-top: 10px;
    padding-bottom: 20px;

    .folder-button {
      transition: all 1s;
      min-height: 75px;
      margin: 10px;
      margin-bottom: 0;
      margin-top: 0;
      padding: 20px;
      padding-top: 10px;
      // padding-bottom: 10px;
      
      border-bottom: 2px solid var(--color-hoval-light-gray);
      border-radius: 0px;
      white-space: wrap;
      display: flex;
      // flex-wrap: wrap;
      justify-items: center;
      // align-items: center;
      // border-radius: 4px;
      overflow: hidden;

      .thumb {
        transition: all 1s;
        overflow: hidden;
        border-radius: 4px;
        width: 100px;
        margin-right: 20px;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .title {
        transition: all 1s;
        font-size: 15pt;
        font-weight: 600;
        color: var(--color-hoval-gray);
        padding-top: 10px;

        .title-play {
          display: flex;
          align-items: center;

          .play {
            height: 25px;
            margin-left: 10px;
            filter: grayscale(100);

            &.selected {
              filter: grayscale(0)
            }
          }
        }

        

        .subtitle {
          font-weight: normal;
          line-height: 18pt;
        }
      }

      &.hidden {
        visibility: hidden;
      }

      &.selected {
        background-color: var(--color-hoval-verylight-gray);
      }

      &.noborder {
        border-bottom: none;
      }
    }
  }

  .video-buttons {
    display: flex;
    flex-direction: column;
    background-color: var(--color-white);
    transition: all 1s;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;

    .video-button {
      transition: all 1s;
      min-height: 70px;
      margin: 10px;
      padding: 20px;
      border-bottom: 2px solid var(--color-hoval-light-gray);
      border-radius: 0px;
      white-space: wrap;
      display: flex;
      // flex-wrap: wrap;
      justify-items: center;
      // align-items: center;
      border-radius: 4px;
      overflow: hidden;

      .thumb {
        overflow: hidden;
        border-radius: 4px;
        width: 100px;
        margin-right: 20px;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .title {
        transition: all 1s;
        font-size: 15pt;
        font-weight: 600;
        color: var(--color-hoval-gray);

        .subtitle {
          font-weight: normal;
        }
      }

      &.hidden {
        visibility: hidden;
      }

      &.selected {
        background-color: var(--color-hoval-verylight-gray);
      }
    }
  }
}
