// nutze Client Settings
@import "./../global.scss";

.video-player {
  width: 100%;
  height: 100%; // calc(100% / (16 / 9));
  // background-color: var(--color-white);
  display: block;

  .video-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // background-color: var(--color-white);
    position: relative;
    padding: 0;
    margin: auto;
    // transition: all 1s;

    .wrapper {
      width: 100%;
      height: 45vh;
      min-height: 52.9vh;
      // border: 2px solid #fff;
      // height: 100%;
      background-color: rgba($color: #000000, $alpha: 0.4);
      position: relative;
      padding: 0;
      margin: 0;
      // margin-top: 8%;
      overflow: hidden;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 1s;

      &.fullscreen {
        height: 79vh;
        background-color: transparent;
      }

      &.touch {
        height: 64vh;
      }

      // .react-player {
      //   // transition: all 1s;
      // }
    }
    .controls {
      position: relative;
      width: 100%;
      top: 8px;
      left: 5px;
      padding-right: 20px;
      display: flex;
      justify-items: center;
      align-items: center;
      transition: all 1s;

      &.fullscreen {
        top: -10px;
        opacity: 0;

        &.touch {
          top: 0;
        }
      }

      svg {
        height: 50px;
        max-height: 50px;
      }

      .pause {
        padding-right: 20px;
        height: 70px;
        max-height: 70px;

        &:focus {
          outline: none;
        }
      }

      .loop {
        padding-right: 20px;
      }

      .pos, input[type=range] {
        // transition: all 0.5s;
        margin-right: 10px;
        width: 100%;
      }

      .duration {
        color: white;
        font-size: 15pt;
        margin-top: 1px;
        margin-right: 40px;
      }

      .volume {
        margin-right: 20px;
      }

      .volume-slider {
        width: 130px;
        margin-right: 50px;
      }

      .fullscreen {
        margin-right: 20px;
        height: 70px;
        max-height: 70px;
      }
    }

    .stats {
      position: absolute;
      display: flex;
      justify-content: flex-end;
      top: 10px;
      right: 10px;
      font-size: 8pt;
      display: none;
    }
  }
}
