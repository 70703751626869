// nutze Client Settings
@import "./../global.scss";

.screensaver {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  display: block;
  transition: all 1s;
  background-color: var(--color-hoval-red);
  background-image: url(./../assets/images/background.png);
  background-size: cover;

  &.cordova {
    height: calc(100vh - 50px);
  }

  .react-player {
    transition: all 1s;
    z-index: 12;
    background-color: black;
    opacity: 0;
    &.show {
        opacity: 1;
    }
  }

  .status {
      color: white;
      font-size: 10pt;
      position: absolute;
      bottom: 5px;
      right: 10px;

      &.disabled {
        display: none;
    }
  }

  .logo {
    z-index: 11;
    border: 1px solid #fff;
    width: fit-content;
    position: absolute;
    left: 30px;
    top: 20px;
    img {
      width: 150px;
    }

    &.disabled {
        display: none;
    }

    &.bottom {
      top: 40px;
      left: unset;
      right: 50px;
    }
  }

  .playlist {
    z-index: 9;
    position: absolute;
    left: 40px;
    top: 120px;
    color: white;

    &.disabled {
        display: none;
    }

    &.bottom {
      top: 480px;
    }

    .title {
      font-size: 25pt;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
    }

    .play {
      padding-left: 20px;
      height: 60px;
    }

    .input-stepper {
        padding-bottom: 30px;
        font-size: 15pt;
        button, input {
            font-size: 15pt;
            min-width: 40px;
            height: 40px;
            background-color: transparent;
            color: white;
            border: 1px solid #fff;
        }  
        input {
            width: 70px;
            height: 36px;
            padding-left: 10px;
            border: none;
        }
    }

    .check {
      font-size: 15pt;
      padding-bottom: 10px;

      [type="checkbox"] {
        position: relative;
        left: 20px;
        top: 0px;
        z-index: 0;
        -webkit-appearance: none;
      }
      [type="checkbox"] + label {
        position: relative;
        display: block;
        cursor: pointer;
        // font-family: sans-serif;
        // font-size: 30px;
        line-height: 1.2;
        padding-left: 70px;
        position: relative;
        margin-top: -30px;
      }
      [type="checkbox"] + label:before {
        width: 50px;
        height: 20px;
        border-radius: 20px;
        border: 2px solid #ddd;
        background-color: #eee;
        content: "";
        margin-right: 15px;
        transition: background-color 0.5s linear;
        z-index: 5;
        position: absolute;
        left: 0px;
      }
      [type="checkbox"] + label:after {
        width: 20px;
        height: 20px;
        border-radius: 20px;
        background-color: #fff;
        content: "";
        transition: margin 0.1s linear;
        box-shadow: 0px 0px 5px #aaa;
        position: absolute;
        left: 2px;
        top: 2px;
        z-index: 10;
      }
      [type="checkbox"]:checked + label:before {
        background-color: #2b8718;
      }
      [type="checkbox"]:checked + label:after {
        margin: 0 0 0 30px;
      }
    }
  }
}
