// nutze Client Settings
@import "./../global.scss";

.videoplayer {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  display: block;
  transition: all 1s;
  background-color: var(--color-hoval-red);
  background-image: url(./../assets/images/background.png);
  background-size: cover;

  .react-player {
    transition: all 1s;
    z-index: 10;
    background-color: black;
  }
}
