// nutze Client Settings
@import "./../global.scss";

.overview {
  width: 100%;
  height: 100vh;
  position: relative;
  top: 0;
  display: block;
  transition: all 1s;
  background-color: var(--color-hoval-red);
  background-image: url(./../assets/images/background.png);
  background-size: cover;

  &.fullscreen {
    top: -100px;
    height: calc(100% + 100px);
  }

  &.cordova {
    height: calc(100vh - 50px);
  }

  .logo {
    z-index: 11;
    border: 1px solid #fff;
    width: fit-content;
    position: absolute;
    left: 30px;
    top: 20px;
    img {
      width: 150px;
    }

    &.touch {
      top: 20px;
      left: 50px;
      img {
        width: 200px;
      }
    }

    &.bottom {
      top: 40px;
      left: unset;
      right: 50px;
    }
  }

  .header {
    z-index: 10;
    position: absolute;
    width: 100%;
    top: 120px;
    left: 90px;
    opacity: 0;

    font-weight: bold;
    color: var(--color-white);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: all 1s;

    &.touch {
      top: 150px;
    }

    &.bottom {
      bottom: 50px;
      top: unset;
      width: 30%;
    }

    .title {
      display: flex;
      transform: scale(1);
      transform-origin: 0 0;
      transition: all 1s;

      .str {
        display: flex;
        align-items: center;
        font-size: 30pt;
        white-space: nowrap;

        .right {
          height: 30px;
          margin-left: 10px;
          margin-top: 2px;
        }
      }
    }

    &.big {
      left: 80px;
      opacity: 1;
    }

    &.small {
      // font-size: 20pt;
      // left: -40px;
      opacity: 1;

      .title {
        display: flex;
        transform: scale(0.6);
        transform-origin: 0px 30px;
      }

      .str {
        margin-right: 10px;
      }
    }

    &.smaller {
      // font-size: 15pt;
      left: 30px;
      // transform: scale(1);
      // transform-origin: -10px 40px;
      opacity: 1;

      .title {
        display: flex;
        transform: scale(0.8);
        transform-origin: 0px 30px;
        // transition: all 1s;

        .str {
          margin-right: 10px;
        }
      }
    }

    .back {
      height: 35px;
      min-width: 35px;
      margin-right: 20px;
    }
  }

  .footer {
    position: absolute;
    transition: all 1s;
    bottom: 0;
    z-index: 9;
    color: #fff;
    width: calc(100% - 50px);
    padding: 20px;
    padding-right: 20px;
    text-align: right;
    opacity: 1;

    .playlist {
      height: 60px;
      overflow: hidden;
      transition: all 1s;

      &.disabled {
        opacity: 0;
        height: 0;
      }
    }
  }

  .content {
    z-index: 8;
    position: absolute;
    top: 40px;
    display: flex;
    height: calc(100% - 40px);
    width: calc(100vw - 30px);
    left: 15px;
    transition: all 1s;

    &.fullscreen {
      left: calc(-40% - 20px);
      width: 135%;
    }

    &.touch {
      top: 100px;
    }

    .video {
      display: block;
      width: 65%;
      overflow: hidden;
      // height: 80%;
      transition: all 1s;
      margin: auto;
      margin-top: 170px;

      &.fullscreen {
        // border: 2px solid #000;
        margin-left: 8%;
        margin-top: 140px;
        width: 100%;
        height: 80%;

        &.touch {
          margin-left: 14%;
        }

        &.bottom {
          margin-top: 100px;
        }
      }

      &.cordova {
        width: 67%;
      }

      &.disabled {
        width: 0%;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}
